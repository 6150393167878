import { api } from "../constants/string";
import { HTTP } from "../axios/axios";

export function NotesPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/notes/`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}
export function NotesCustomerGet(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/notes/${id}/customer`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}
export function NotesUserGet(id, page, rows) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/notes/${id}/user?page=${page}&size=${rows}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}
export function NotesGet() {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/notes`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}
